const days = []

const day = (date, config) => days.push({
  date,
  ...config,
})

day(1, {
  size: [2, 2],
  coords: {
    mobile: [0, 10],
    desktop: [0, 6],
  },
  corners: {}
})

day(2, {
  size: [1, 1],
  coords: {
    mobile: [0, 23],
    desktop: [0, 13],
  },
  corners: {
    tl: 1,
    tr: 1,
    bl: 1,
    br: 1
  }
})

day(3, {
  size: [1, 2],
  coords: {
    mobile: [2, 5],
    desktop: [5, 3],
  },
  corners: {
    tl: 1,
    tr: 1
  }
})

day(4, {
  size: [2, 1],
  coords: {
    mobile: [1, 8],
    desktop: [0, 5],
  },
  corners: {}
})

day(5, {
  size: [2, 2],
  coords: {
    mobile: [0, 21],
    desktop: [3, 12],
  },
  corners: {}
})

day(6, {
  size: [1, 2],
  coords: {
    mobile: [1, 28],
    desktop: [1, 16],
  },
  corners: {
    br: 1,
    tr: 1
  }
})

day(7, {
  size: [1, 2],
  coords: {
    mobile: [1, 31],
    desktop: [4, 17],
  },
  corners: {
    tl: 1,
    tr: 1,
    bl: 1,
    br: 1
  }
})

day(8, {
  size: [1, 2],
  coords: {
    mobile: [1, 51],
    desktop: [1, 27],
  },
  corners: {
    bl: 1
  }
})

day(9, {
  size: [1, 2],
  coords: {
    mobile: [2, 11],
    desktop: [2, 7],
  },
  corners: {
    bl: 1,
    br: 1
  }
})

day(10, {
  size: [1, 1],
  coords: {
    mobile: [1, 23],
    desktop: [1, 13],
  },
  corners: {}
})

day(11, {
  size: [1, 1],
  coords: {
    mobile: [2, 27],
    desktop: [2, 15],
  },
  corners: {}
})

day(12, {
  size: [2, 1],
  coords: {
    mobile: [1, 41],
    desktop: [4, 23],
  },
  corners: {
    tr: 1,
    bl: 1
  }
})

day(13, {
  size: [1, 1],
  coords: {
    mobile: [0, 13],
    desktop: [4, 8],
  },
  corners: {
    tl: 1,
    tr: 1,
    bl: 1,
    br: 1
  }
})

day(14, {
  size: [1, 1],
  coords: {
    mobile: [1, 6],
    desktop: [4, 4],
  },
  corners: {}
})

day(15, {
  size: [2, 1],
  coords: {
    mobile: [1, 43],
    desktop: [1, 24],
  },
  corners: {
    tr: 1,
    br: 1
  }
})

day(16, {
  size: [1, 2],
  coords: {
    mobile: [2, 19],
    desktop: [5, 11],
  },
  corners: {}
})

day(17, {
  size: [1, 1],
  coords: {
    mobile: [2, 36],
    desktop: [2, 20],
  },
  corners: {}
})

day(18, {
  size: [2, 2],
  coords: {
    mobile: [1, 45],
    desktop: [4, 24],
  },
  corners: {}
})

day(19, {
  size: [2, 1],
  coords: {
    mobile: [0, 25],
    desktop: [3, 14],
  },
  corners: {
    br: 1
  }
})

day(20, {
  size: [2, 1],
  coords: {
    mobile: [0, 17],
    desktop: [3, 10],
  },
  corners: {
    tl: 1
  }
})

day(21, {
  size: [1, 2],
  coords: {
    mobile: [2, 51],
    desktop: [2, 27],
  },
  corners: {
    tr: 1,
    br: 1
  }
})

day(22, {
  size: [1, 2],
  coords: {
    mobile: [1, 14],
    desktop: [1, 9],
  },
  corners: {
    tl: 1,
    br: 1
  }
})

day(23, {
  size: [1, 1],
  coords: {
    mobile: [0, 2],
    desktop: [0, 2],
  },
  corners: {
    tr: 1
  }
})

day(24, {
  size: [2, 1],
  coords: {
    mobile: [0, 7],
    desktop: [3, 5],
  },
  corners: {}
})

day(25, {
  size: [2, 2],
  coords: {
    mobile: [0, 39],
    desktop: [3, 21],
  },
  corners: {
    tr: 1,
    bl: 1
  }
})

day(26, {
  size: [1, 2],
  coords: {
    mobile: [2, 31],
    desktop: [5, 17],
  },
  corners: {
    tr: 1,
    bl: 1
  }
})

day(27, {
  size: [1, 2],
  coords: {
    mobile: [0, 46],
    desktop: [3, 25],
  },
  corners: {}
})

day(28, {
  size: [1, 1],
  coords: {
    mobile: [0, 45],
    desktop: [3, 24],
  },
  corners: {
    bl: 1,
    br: 1,
    tl: 1,
    tr: 1,
  }
})

day(29, {
  size: [1, 1],
  coords: {
    mobile: [0, 32],
    desktop: [3, 18],
  },
  corners: {
    tl: 1,
    tr: 1,
    bl: 1,
    br: 1
  }
})

day(30, {
  size: [1, 1],
  coords: {
    mobile: [0, 50],
    desktop: [0, 26],
  },
  corners: {}
})

day(31, {
  size: [1, 1],
  coords: {
    mobile: [1, 37],
    desktop: [1, 21],
  },
  corners: {}
})

export default days
