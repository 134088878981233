import isDev from './is-dev'
import cache from './cache'

let shouldDebug = isDev
let lastEventKey = 0

const api = {}
const events = {}
cache.set('events', events)

const registerEvent = (name, callback) => {
  window.addEventListener(name, callback)
  events[++lastEventKey] = {
    name,
    callback
  }
  return lastEventKey
}

api.debug = () => shouldDebug = true

api.dispatch = (name, data = {}) => {
  if (shouldDebug) {
    window.APP = window.APP || {}
    window.APP.events = events
    console.log(`%cdispatch %c${name}`, '', 'font-weight:bold;', data)
  }

  const event = document.createEvent('CustomEvent')
  event.initCustomEvent(name, false, false, data)

  window.dispatchEvent(event)
}

api.off = (eventKey, next) => {
  const event = events[eventKey] || null
  if (!event) return
  window.removeEventListener(event.name, event.callback)
  delete events[eventKey]
  if (typeof next === 'function') next()
}

api.on = (name, next) => {
  const callback = e => {
    if (typeof e.detail === 'object') {
      return next(e.detail)
    }
    next(e)
  }
  const eventKey = registerEvent(name, callback)
  return eventKey
}

api.once = (name, next) => {
  let eventKey = null
  const callback = e => {
    if (typeof e.detail === 'object') {
      return next(e.detail)
    }
    next(e)
    api.off(eventKey)
  }
  eventKey = registerEvent(name, callback)
  return eventKey
}

export default api
