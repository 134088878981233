import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { DEBUG_CALENDAR } from '../../../../functions/env'

const useStyles = makeStyles(theme => ({
  TileGraphic: {
    border: DEBUG_CALENDAR ? '1px solid yellow' : 'none',
    pointerEvents: 'none',
    '& > div': {
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%'
    }
  },
}))

export default ({ className, style, tile }) => {
  const classes = useStyles()
  return (
    <div className={classes.TileGraphic + ' ' + className}
      style={style}>
      {DEBUG_CALENDAR && <p>graphic-{tile.name}</p>}
      <div style={{
        backgroundImage: `url(/calendar/graphics/svg-${tile.name}_static.svg)`
      }} />
    </div>
  )
}
