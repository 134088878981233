import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'

const useStyles = makeStyles(theme => ({
  Button: {
    background: theme.palette.pink,
    height: 60,
    padding: '0 28px',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
    transition: 'background 300ms ease',
    '&:hover': theme.mixins.hover({
      background: theme.palette.white
    }),
    '&[data-submit="true"]:hover': theme.mixins.hover({
      background: theme.palette.purple
    }),
  },
  Button_Text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& span': {
      color: theme.palette.red,
      fontSize: 20,
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    '&[data-black="true"] span': {
      color: theme.palette.black,
      fontSize: 16,
      lineHeight: '20px',
    }
  }
}))

export default ({ submit, text, onClick, black }) => {
  const classes = useStyles()
  return (
    <div className={classes.Button} data-submit={submit} onClick={onClick}>
      <div className={classes.Button_Text} data-black={black}>
        <span {...fontProps('Lato', 'Bold')}>{text}</span>
      </div>
    </div>
  )
}
