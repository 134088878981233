import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import cache from '../../../helpers/cache'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import isTouchScreen from '../../../helpers/is-touch-screen'
import lottie from 'lottie-web'

import { DEBUG_CALENDAR } from '../../../../functions/env'
import DAYS from '../../../data/tiles/days'

const days = {
  available: {},
  unavailable: {},
}
DAYS.forEach(d => {
  days.available[d.date] = require(`../../../../static/calendar/days/day_${d.date}.json`)
  days.unavailable[d.date] = require(`../../../../static/calendar/days/day_${d.date}-unavailable.json`)
})

const useStyles = makeStyles(theme => ({
  TileDay: {
    border: DEBUG_CALENDAR ? '1px solid black' : 'none',
    background: DEBUG_CALENDAR ? 'rgba(0,0,0,0.5)' : 'none',
    '& > div': {
      pointerEvents: 'none',
      position: 'relative',
      height: '100%',
      width: '100%',
      overflow: 'hidden'
    }
  }
}))

const canOpen = {}

export default ({ className, style, tile }) => {
  const classes = useStyles()
  const tileStyle = { ...style }
  delete tileStyle.top
  delete tileStyle.left
  return (
    <div
      className={classes.TileDay + ' ' + className}
      ref={initAnimation(tile.date)}
      style={style}
      onClick={() => {
        if (!canOpen[tile.date]) return
        const font = cache.get('fonts')[tile.date] || null
        if (font) events.dispatch(actions.SHOW_FONT_MODAL, { font })
      }}
    >
      {DEBUG_CALENDAR && <p>day-{tile.date}</p>}
      <div style={tileStyle} />
    </div>
  )
}

const isAvailable = date => !!cache.get('fonts')[date]

const initAnimation = date => ref => {
  if (!ref) return
  if (cache.get(`animation.calendar.day${date}`)) return

  const available = isAvailable(date)
  const data = days[available ? 'available' : 'unavailable'][date] || null
  if (!data) return

  const animation = lottie.loadAnimation({
    container: ref.querySelector('div'),
    renderer: 'svg',
    autoplay: false,
    animationData: data
  })
  cache.set(`animation.calendar.day${date}`, animation)

  animation.addEventListener('complete', () => {
    if (available && animation.currentFrame > 0) {
      ref.style.cursor = 'pointer'
      canOpen[date] = true
    }
  })

  if (isTouchScreen) {
    let lock = false
    events.on(actions.SCROLL_START, () => {
      lock = true
    })
    events.on(actions.SCROLL_STOP, () => {
      lock = false
    })
    ref.addEventListener('touchend', () => {
      console.log('touchend')
      if (lock) return
      if (canOpen[date]) {
        const font = cache.get('fonts')[date] || null
        if (font) events.dispatch(actions.SHOW_FONT_MODAL, { font })
        return
      }
      if (!animation.isPaused) return
      if (cache.get('modal.font.open')) return
      if (!available) animation.goToAndStop(0)
      animation.play()
    })
  }
  else {
    ref.addEventListener('mouseenter', () => {
      if (!animation.isPaused) return
      if (cache.get('modal.font.open')) return
      if (!available) animation.goToAndStop(0)
      animation.setDirection(1)
      animation.play()
    })
    if (!available) {
      ref.addEventListener('mouseleave', () => {
        animation.setDirection(-1)
        animation.play()
      })
    }
  }
}
