import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import MiniModal from '../MiniModal'
import { fontProps } from '../../../helpers/fonts'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import SocialIcons from '../SocialIcons'

const useStyles = makeStyles(theme => ({
  DownloadModal: {},
  DownloadModal_Header: {
    // border: '1px solid',
    position: 'relative',
    zIndex: 1,
    background: theme.palette.purple,
    padding: theme.spacing(3),
    paddingTop: 0,
    '& *': {
      color: theme.palette.white,
      margin: 0
    },
    '& h1': {
      marginBottom: 30,
      marginTop: -20,
      fontSize: 38,
      lineHeight: '46px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 54,
        lineHeight: '73px',
      }
    },
    '& p': {
      marginBottom: 20,
      fontSize: 16,
      lineHeight: '20px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '24px',
      }
    }
  },
  DownloadModal_Body: {
    background: theme.palette.white,
    padding: theme.spacing(1),
    paddingBottom: 40,
    paddingTop: 40,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      paddingBottom: 60,
      paddingTop: 60,
    }
  },
}))

export default ({ isOpen, font }) => {
  const classes = useStyles()
  return (
    <MiniModal isOpen={isOpen} className={classes.DownloadModal} onClose={() => {
      events.dispatch(actions.HIDE_DOWNLOAD_MODAL)
    }}>
      <div className={classes.DownloadModal_Header}>
        <h1 {...fontProps('Bree Serif')}>Merry typing!</h1>
        <p {...fontProps('Lato')}>Keep the fun going by coming back each morning for a freshly picked font, hand-wrapped for you—and by sharing the site with your friends.</p>
      </div>
      <div className={classes.DownloadModal_Body}>
        <SocialIcons copy />
      </div>
    </MiniModal>
  )
}
