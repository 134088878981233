import React from "react"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  Avatar: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      height: '100%',
      width: '100%',
    },
    '&.border': {
      border: `3px solid ${theme.palette.green}`,
      '& > div': {
        height: 'calc(100% - 30px)',
        width: 'calc(100% - 30px)',
      }
    }
  },
  Avatar_Image: {
    backgroundRepeat: 'no-repeat',
  },
  Avatar_Text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default ({ border, bgColor, bgSize, size, radius, position, src, text }) => {
  const classes = useStyles()
  let className = classes.Avatar
  if (border) className += ' border'
  const style = {}
  if (size) {
    style.height = size
    style.width = size
  }
  style.borderRadius = radius || '100%'
  if (!src && bgColor) {
    style.background = bgColor
  }
  return (
    <div className={className} style={style}>
      {src && (
        <div className={classes.Avatar_Image} style={{
          backgroundImage: `url(${src})`,
          backgroundSize: bgSize || 'cover',
          borderRadius: radius || '100%',
          backgroundPosition: position || 'center center',
        }} />
      )}
      {text && <div className={classes.Avatar_Text}>{text}</div>}
    </div>
  )
}
