import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import MiniModal from '../MiniModal'
import ContactForm from '../ContactForm'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import { fontProps } from '../../../helpers/fonts'

const useStyles = makeStyles(theme => ({
  ContactModal: {},
  ContactModal_Header: {
    // border: '1px solid',
    position: 'relative',
    zIndex: 1,
    background: theme.palette.purple,
    padding: theme.spacing(3),
    paddingTop: 0,
    '& h1': {
      color: theme.palette.white,
      marginBottom: 30,
      marginTop: -20,
      width: '90%',
      fontSize: 38,
      lineHeight: '46px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 54,
        lineHeight: '73px',
      }
    },
  },
  ContactModal_Body: {
    background: theme.palette.white,
    padding: theme.spacing(3),
    paddingBottom: 40,
    paddingTop: 40,
  },
}))

export default ({ isOpen, font }) => {
  const classes = useStyles()
  return (
    <MiniModal isOpen={isOpen} className={classes.ContactModal} onClose={() => {
      events.dispatch(actions.HIDE_CONTACT_MODAL)
    }}>
      <div className={classes.ContactModal_Header}>
        <h1 {...fontProps('Bree Serif')}>We font you to say hi, so don’t be shy!</h1>
      </div>
      <div className={classes.ContactModal_Body}>
        <ContactForm />
      </div>
    </MiniModal>
  )
}
