import React from "react"
import cache from '../../../helpers/cache'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import { getFonts } from '../../../helpers/api'
import isMobile from '../../../helpers/is-mobile'
import { downloadFont } from '../../../helpers/fonts'
import Navbar from '../../shared/Navbar'
import Intro from '../../shared/Intro'
import Calendar from '../../shared/Calendar'
import Footer from '../../shared/Footer'
import FontModal from '../../shared/FontModal'
import DownloadModal from '../../shared/DownloadModal'
import ContactModal from '../../shared/ContactModal'
import Fonts from '../../shared/Fonts'

export default () => {
  const [mobile, setMobile] = React.useState(isMobile())
  const [ fontsReady, setFontsReady ] = React.useState(null)
  const [ introComplete, setIntroComplete ] = React.useState(null)
  const [ controller, setController ] = React.useState(null)
  const [ showFontModal, setShowFontModal ] = React.useState(false)
  const [ showContactModal, setShowContactModal ] = React.useState(false)
  const [ showDownloadModal, setShowDownloadModal ] = React.useState(false)

  React.useEffect(() => {
    fetchFonts(setFontsReady, setController)
    addScrollHandler()
    addResizeHandler(setMobile)
    addModalHandlers(setShowFontModal, setShowDownloadModal, setShowContactModal)
    addKeyDownHandler()
    events.once(actions.INTRO_COMPLETE, () => {
      setIntroComplete(true)
    })
  }, [])

  const pageProps = { controller, mobile }

  const fonts = fontsReady
    ? Object.keys(cache.get('fonts'))
        .filter(key => !isNaN(key))
        .map(key => cache.get('fonts')[key])
    : []

  return (
    <>
      <Intro {...pageProps} />
      {fontsReady && introComplete && (
        <>
          <Fonts fonts={fonts} />
          <Navbar {...pageProps} black social />
          <Calendar {...pageProps} />
          <Footer />
          <FontModal {...pageProps} isOpen={showFontModal} />
          <DownloadModal {...pageProps} isOpen={showDownloadModal} />
          <ContactModal {...pageProps} isOpen={showContactModal} />
        </>
      )}
    </>
  )
}

const fetchFonts = (setFontsReady, setController) => {
  // Get fonts from server
  getFonts((err, res) => {
    if (err) return
    cache.set('fonts', res.data)

    // Init ScrollMagic
    const ctrl = new window.ScrollMagic.Controller()
    setController(ctrl)
    cache.set('controller', ctrl)

    setFontsReady(true)
  })
}

const addScrollHandler = () => {
  let isScrolling = false
  let scrollTimer = null
  const scroll = () => {
    if (scrollTimer) {
      clearTimeout(scrollTimer)
      scrollTimer = null
    }
    if (!isScrolling) {
      isScrolling = true
      events.dispatch(actions.SCROLL_START)
    }
    events.dispatch(actions.SCROLL, {
      x: window.pageXOffset,
      y: window.pageYOffset,
    })
    scrollTimer = setTimeout(() => {
      isScrolling = false
      events.dispatch(actions.SCROLL_STOP)
    }, 100)
  }
  window.addEventListener('scroll', scroll)
}

const addResizeHandler = setMobile => {
  let isResizing = false
  let resizeTimer = null
  const resize = () => {
    const mobile = isMobile()
    setMobile(mobile)
    if (resizeTimer) {
      clearTimeout(resizeTimer)
      resizeTimer = null
    }
    if (!isResizing) {
      isResizing = true
      events.dispatch(actions.RESIZE_START)
    }
    events.dispatch(actions.RESIZE, {
      mobile,
      h: window.innerHeight,
      w: window.innerWidth,
    })
    resizeTimer = setTimeout(() => {
      isResizing = false
      events.dispatch(actions.RESIZE_STOP)
    }, 100)
  }
  window.addEventListener('resize', resize)
  resize()
}

const toggleBodyScroll = () => {
  window.document.body.style.overflow = (
    cache.get('modal.font.open') ||
    cache.get('modal.download.open') ||
    cache.get('modal.contact.open')
  ) ? 'hidden' : 'unset'
}

const addModalHandlers = (setShowFontModal, setShowDownloadModal, setShowContactModal) => {
  // Font Modal Handler
  events.on(actions.SHOW_FONT_MODAL, ({ font }) => {
    cache.set('current.font', font)
    cache.set('modal.font.open', !!font)
    toggleBodyScroll()
    setShowFontModal(true)
  })
  events.on(actions.HIDE_FONT_MODAL, () => {
    cache.set('current.font', null)
    cache.set('modal.font.open', false)
    toggleBodyScroll()
    setShowFontModal(false)
  })

  // Download Modal Handler
  events.on(actions.SHOW_DOWNLOAD_MODAL, () => {
    cache.set('modal.download.open', true)
    toggleBodyScroll()
    const font = cache.get('current.font')
    downloadFont(font)
    setShowDownloadModal(true)
  })
  events.on(actions.HIDE_DOWNLOAD_MODAL, () => {
    cache.set('modal.download.open', false)
    toggleBodyScroll()
    setShowDownloadModal(false)
  })

  // Contact Modal Handler
  events.on(actions.SHOW_CONTACT_MODAL, () => {
    cache.set('modal.contact.open', true)
    toggleBodyScroll()
    setShowContactModal(true)
  })
  events.on(actions.HIDE_CONTACT_MODAL, () => {
    cache.set('modal.contact.open', false)
    toggleBodyScroll()
    setShowContactModal(false)
  })
}

const addKeyDownHandler = () => {
  window.document.addEventListener('keydown', e => {
    if (!e) return
    if (e.keyCode === 27) { // ESC
      if (cache.get('modal.download.open')) {
        events.dispatch(actions.HIDE_DOWNLOAD_MODAL)
      }
      else if (cache.get('modal.contact.open')) {
        events.dispatch(actions.HIDE_CONTACT_MODAL)
      }
      else if (cache.get('modal.font.open')) {
        events.dispatch(actions.HIDE_FONT_MODAL)
      }
    }
  })
}

