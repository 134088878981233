import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as Close } from '../../../svg/close-icon.svg'

const useStyles = makeStyles(theme => ({
  MiniModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 102,
    height: '100%',
    width: '100%',
    overflow: 'scroll',
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity 600ms ease',
    '& *': {
      pointerEvents: 'none',
    },
    '&.open': {
      opacity: 1,
      pointerEvents: 'all',
      '& *': {
        pointerEvents: 'all',
      },
    }
  },
  MiniModal_Overlay: {
    position: 'fixed',
    background: 'rgba(0,0,0,0.8)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  MiniModal_Content: {
    background: theme.palette.white,
    // height: 500,
    width: '90%',
    maxWidth: 600,
    margin: '120px auto',
    position: 'relative',
  },
  MiniModal_ContentHeader: {
    // border: '1px solid red',
    height: theme.vars.logoHeight,
    width: '100%',
    background: theme.palette.purple,
    position: 'relative',
  },
  MiniModal_Close: {
    // border: '1px solid',
    position: 'absolute',
    height: theme.vars.logoHeight,
    width: theme.vars.logoHeight,
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      cursor: 'pointer',
      height: '40%',
      width: '40%',
      '& path': {
        fill: theme.palette.white,
        transition: 'fill 300ms ease'
      },
      '&:hover': theme.mixins.hover({
        '& path': {
          fill: theme.palette.pink
        }
      })
    }
  },
}))

export default ({ isOpen, className, onClose, children }) => {
  const classes = useStyles()
  return (
    <div className={`${classes.MiniModal}${isOpen ? ' open' : ''}`}>
      <div className={classes.MiniModal_Overlay} onClick={onClose} />
      <div className={classes.MiniModal_Content}>
        <div className={classes.MiniModal_ContentHeader}>
          <div className={classes.MiniModal_Close}>
            <Close onClick={onClose} />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
