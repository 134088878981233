import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import cache from '../../../helpers/cache'
import Header from './Header'
import Body from './Body'
import Footer from '../Footer'

const useStyles = makeStyles(theme => ({
  FontModal: {
    background: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 101,
    height: '100%',
    width: '100%',
    overflow: 'scroll',
    pointerEvents: 'none',
    opacity: 0,
    transition: 'opacity 600ms ease',
    '& *': {
      pointerEvents: 'none',
    },
    '&.open': {
      opacity: 1,
      pointerEvents: 'all',
      '& *': {
        pointerEvents: 'all',
      },
    }
  },
}))

export default props => {
  const classes = useStyles()

  const [font, setFont] = React.useState(null)
  const [ref, setRef] = React.useState(null)

  React.useEffect(() => {
    if (props.isOpen) {
      if (ref && ref.scrollTo) ref.scrollTo(0, 0)
      setFont(cache.get('current.font'))
    }
  }, [props.isOpen, ref])

  return (
    <div className={`${classes.FontModal}${props.isOpen ? ' open' : ''}`} ref={setRef}>
      <Header {...props} font={font} />
      <Body {...props} font={font} />
      <Footer />
    </div>
  )
}
