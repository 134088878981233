import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'
import cache from '../../../helpers/cache'
import Avatar from '../Avatar'
import Detail from './Detail'

const useStyles = makeStyles(theme => ({
  Designers: {
    '& p': {
      color: theme.palette.black,
      margin: 0,
    },
    '& a': {
      color: theme.palette.red,
      transition: 'color 300ms ease',
      '&:hover': theme.mixins.hover({
        color: theme.palette.pink
      })
    },
    [theme.breakpoints.up('md')]: {
      // paddingLeft: 32,
      paddingRight: 32
    }
  },
  Designers_Designer: {
    '&:not(:last-child)': {
      marginBottom: 15,
    }
  },
  Designers_Designer_Header: {
    border: '1px solid',
    display: 'flex',
    '& p': {
      fontSize: 14,
      lineHeight: '17px',
    },
    '& + div': {
      marginTop: 15,
    }
  },
  Designers_Designer_Header_Image: {
    marginRight: 15,
    width: 50
  },
  Designers_Designer_Header_Text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& p:nth-child(2)': {
      marginTop: 5
    }
  },
  Designers_Designer_Body: {
    '& p': {
      fontFamily: 'Lato',
      fontWeight: 400,
      '&:not(:last-child)': {
        marginBottom: 20,
      }
    },
  },
}))

export default ({ font, setCurrentFont }) => {
  const classes = useStyles()
  font = font || { designers: [] }
  const theme = cache.get('theme')
  return (
    <div className={classes.Designers}>
      <Detail title="TYPEFACE DESIGNERS">
        {font.designers.map((designer, i) => (
          <div key={i} className={classes.Designers_Designer}>
            <div className={classes.Designers_Designer_Header}>
              <div className={classes.Designers_Designer_Header_Image}>
                <Avatar size={42} src={designer.image} bgColor={theme.palette.red} text={!designer.image && (() => {
                  return designer.name.split(' ').map(name => (name[0] || '').toUpperCase()).join('')
                })()} />
              </div>
              <div className={classes.Designers_Designer_Header_Text}>
                <p {...fontProps('Lato', 'Bold')}>{designer.name}</p>
                {i === 0 && <p {...fontProps('Lato')}>Principal Design</p>}
              </div>
            </div>
            {designer.bio && <div className={classes.Designers_Designer_Body} dangerouslySetInnerHTML={{__html: designer.bio}} />}
          </div>
        ))}
      </Detail>
    </div>
  )
}
