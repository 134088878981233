import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'
import Button from '../Button'

const useStyles = makeStyles(theme => ({
  Header: {
    // border: '1px solid white',
    minWidth: 300,
    marginBottom: 130,
    paddingTop: 80,
    marginLeft: '5%',
    paddingRight: '5%',
    '& > *': {
      margin: 0,
      // maxWidth: 300,
      maxWidth: 400,
    },
    '& h1': {
      // fontSize: 44,
      // lineHeight: '54px',
      fontSize: 54,
      lineHeight: '60px',
      marginBottom: 40
    },
    '& h2': {
      fontSize: 16,
      lineHeight: '22px',
      marginBottom: 50
    },
    [theme.breakpoints.up(750)]: {
      '& > *': {
        maxWidth: 700,
      },
      '& h1': {
        fontSize: 64,
        lineHeight: '74px',
      },
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '16.67%',
      paddingRight: 0,
      '& > *': {
        maxWidth: 800,
      },
      '& h1': {
        fontSize: 74,
        lineHeight: '94px',
        marginBottom: 50
      },
      '& h2': {
        fontSize: 18,
        lineHeight: '26px',
        // marginBottom: 70
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& > *': {
        maxWidth: 1020,
      },
      '& h1': {
        fontSize: 86,
        lineHeight: '110px',
      },
      '& h2': {
        fontSize: 20,
        lineHeight: '30px',
      }
    },
    [theme.breakpoints.up('xl')]: {
      '& h1': {
        fontSize: 96,
        lineHeight: '122px',
      },
      '& h2': {
        fontSize: 22,
        lineHeight: '32px',
      }
    }
  },
}))

const title = 'Unwrap a typeface for every day of December.'
const description = `We asked 31 designers, artists, and typophiles to share their favorite fonts, and wrapped them up into a holiday calendar filled with typographic surprises to spark some yuletide joy and inspiration for the new year. Look for clues leading to today’s date, and come back for the new glyph-gift that will unlock each morning.`

export default () => {
  const classes = useStyles()
  return (
    <div className={classes.Header}>
      <h1 {...fontProps('Bree Serif')}>{title}</h1>
      <h2 {...fontProps('Lato')}>{description}</h2>
      <Button text="Start Unwrapping" onClick={scrollToGrid(classes)} />
    </div>
  )
}

const scrollToGrid = classes => () => {
  if (!window.TweenMax) return
  const grid = window.document.querySelector(`.${classes.Header} + div`)
  window.TweenMax.to(window, 1, {
    scrollTo: {
      y: grid.offsetTop,
      autoKill: false
    },
    ease: 'circ.out',
  })
}
