import React from "react"
import get from 'lodash.get'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { fontProps, getStyles } from '../../../helpers/fonts'
import Detail from './Detail'

const useStyles = makeStyles(theme => ({
  Styles: {
    width: '100%',
    '& p': {
      color: theme.palette.black,
      fontSize: 24,
      lineHeight: '35px',
      margin: 0,
    },
  },
  Styles_Container: {
    width: '100%'
  }
}))

export default ({ mobile, font, setCurrentFont }) => {
  const classes = useStyles()
  font = font || {}

  const styles = getStyles(get(font, 'styles', ''))
  const desktopStyles = [...styles]
  let col1 = []
  let col2 = []
  let col3 = []

  if (styles.length === 1) {
    col1 = desktopStyles
  }
  else if (styles.length === 2) {
    col1 = desktopStyles.splice(0, 1)
    col2 = desktopStyles
  }
  else if (styles.length === 4) {
    col1 = desktopStyles.splice(0, 2)
    col2 = desktopStyles
  }
  else {
    col1 = desktopStyles.splice(0, Math.ceil(styles.length / 3))
    col2 = desktopStyles.splice(0, Math.ceil(styles.length / 3))
    col3 = desktopStyles
  }

  return (
    <div className={classes.Styles}>
      <Detail title="STYLES">
        {mobile ? (
          <Grid container className={classes.Styles_Container}>
            {styles.map(style => (
              <Grid key={style.name} item xs={12} sm={6}>
                <p {...fontProps(font.name, style.name)}>{style.name}</p>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container className={classes.Styles_Container}>
            <Grid item xs={4}>
              {col1.map(style => (
                <p key={style.name} {...fontProps(font.name, style.name)}>{style.name}</p>
              ))}
            </Grid>
            <Grid item xs={4}>
              {col2.map(style => (
                <p key={style.name} {...fontProps(font.name, style.name)}>{style.name}</p>
              ))}
            </Grid>
            <Grid item xs={4}>
              {col3.map(style => (
                <p key={style.name} {...fontProps(font.name, style.name)}>{style.name}</p>
              ))}
            </Grid>
          </Grid>
        )}
      </Detail>
    </div>
  )
}
