import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import cache from '../../../helpers/cache'
import actions from '../../../helpers/actions'
import events from '../../../helpers/events'
import lottie from 'lottie-web'
import { ReactComponent as Caret } from '../../../svg/intro-caret.svg'
import { SKIP_INTRO, DEBUG_SCROLLMAGIC } from '../../../../functions/env'

import intro1 from '../../../../static/intro/intro_p1.json'
import intro2 from '../../../../static/intro/intro_p2.json'
import intro3 from '../../../../static/intro/intro_p3.json'

const useStyles = makeStyles(theme => ({
  Intro: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: 100,
    '& > div': {
      position: 'absolute',
      right: 0,
      left: 0,
      margin: 'auto'
    }
  },
  Intro_Animation: {
    // border: '1px dashed red',
    height: '100%',
    width: '100%',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }
  },
  Intro_Caret: {
    bottom: '5%',
    height: 19,
    width: 32,
    cursor: 'pointer',
    opacity: 0,
    pointerEvents: 'none',
    '& svg path': {
      transition: 'fill 300ms ease'
    },
    '&:hover svg path': theme.mixins.hover({
      fill: theme.palette.pink
    })
  },
}))

export default ({ controller }) => {
  const classes = useStyles()
  return (
    <div className={classes.Intro} ref={initAnimation(classes, controller)}>

      {/* Animation */}
      <div className={classes.Intro_Animation}>
        <div className="intro-1" />
        <div className="intro-2" />
        <div className="intro-3" />
      </div>

      {/* Caret */}
      <div className={classes.Intro_Caret} onClick={scrollToCalendar}>
        <Caret />
      </div>
    </div>
  )
}

const initAnimation = (classes, controller) => ref => window.requestAnimationFrame(() => {
  if (!ref || !controller) return
  if (cache.get('animation.intro1')) return

  // Autoplay Intro 1 (Logo)
  const animation1 = lottie.loadAnimation({
    container: ref.querySelector('.intro-1'),
    renderer: 'svg',
    autoplay: false,
    animationData: intro1
  })
  cache.set('animation.intro1', animation1)
  if (SKIP_INTRO) animation1.setSpeed(1000)

  // Swap Autoplay Intro with Scroll Intro
  const intro2El = ref.querySelector('.intro-2')
  intro2El.hidden = true
  const animation2 = lottie.loadAnimation({
    container: intro2El,
    renderer: 'svg',
    autoplay: false,
    animationData: intro2
  })
  cache.set('animation.intro2', animation2)

  // Loop Intro 3 (Text)
  const intro3El = ref.querySelector('.intro-3')
  // intro3El.style.opacity = 0
  const animation3 = lottie.loadAnimation({
    container: intro3El,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: intro3
  })
  // animation3.goToAndStop(1000)
  animation3.setSpeed(0.75)
  cache.set('animation.intro3', animation3)

  animation1.play()
  animation1.addEventListener('complete', () => {
    animation1.destroy()
    intro2El.hidden = false

    const tl = new window.TimelineMax({
      onComplete: () => {
        initScene(ref, controller, classes)
        events.dispatch(actions.INTRO_COMPLETE)

        // events.dispatch(actions.INTRO_COMPLETE)
        window.document.body.style.overflow = 'unset'
        // animation3.play()
        setTimeout(() => animation3.play(), 1500)
      }
    })
    const caret = ref.querySelector(`.${classes.Intro_Caret}`)
    tl.to(caret, 0.3, { opacity: 1, pointerEvents: 'all' }, 0)
    // tl.to(intro3El, 0.6, { opacity: 1 }, 0)
  })
})

const initScene = (ref, controller, classes) => window.requestAnimationFrame(() => {
  const { ScrollMagic } = window
  if (!ScrollMagic || !controller) return

  const intro3 = ref.querySelector('.intro-3')
  const caret = ref.querySelector(`.${classes.Intro_Caret}`)

  const scene = new ScrollMagic.Scene({
    duration: '75%',
    offset: 0,
  })

  const tl = new window.TimelineMax()
  tl.to(ref, 1, { pointerEvents: 'none' }, 0) // Set the total duration as 1
  tl.to(intro3, 0.5, { opacity: 0, ease: window.Linear.easeNone, pointerEvents: 'none' }, 0)
  tl.to(caret, 0.15, { opacity: 0, ease: window.Linear.easeNone, overwrite: false, immediateRender: false }, 0)
  tl.to(caret, 0.01, { pointerEvents: 'none' }, 0.15)
  scene.setTween(tl)

  scene.on('progress', () => {
    const progress = scene.progress()
    const animation2 = cache.get('animation.intro2')
    const value = Math.round(animation2.totalFrames * progress)
    animation2.goToAndStop(value, animation2.totalFrames)
  })

  if (DEBUG_SCROLLMAGIC) scene.addIndicators()

  controller.addScene(scene)
  cache.set('scene.intro', scene)
})

const scrollToCalendar = () => {
  if (!window.TweenMax) return
  window.TweenMax.to(window, 1.5, {
    scrollTo: {
      y: window.innerHeight * 1.15,
      autoKill: false
    },
    ease: 'circ.out',
  })
}
