import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'
import Avatar from '../Avatar'
import Detail from './Detail'

const useStyles = makeStyles(theme => ({
  Author: {
    width: '100%',
    '& *': {
      color: theme.palette.black,
      fontSize: 24,
      lineHeight: '35px',
      margin: 0,
    },
  },
  Author_Container: {
    // border: '1px solid red',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  Author_Image: {
    // border: '1px solid black',
    width: 180,
    marginRight: 30,
    marginBottom: 20
  },
  Author_Quote: {
    // border: '1px solid black',
    width: '100%',
    position: 'relative',
  },
  Author_Quote_Icon: {
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 0,
    left: 0,
    width: 20,
    height: 16,
    '& svg': {
      height: '100%',
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      width: 34,
      height: 27,
    }
  },
  Author_Quote_Text: {
    // border: '1px solid black',
    fontSize: 14,
    lineHeight: '20px',
    paddingTop: 12,
    paddingLeft: 22,
    marginBottom: 30,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
      paddingTop: 20,
      paddingLeft: 40,
    }
  },
  Author_Quote_Designer: {
    // border: '1px solid black',
    paddingLeft: '40%',
    '& p': {
      color: theme.palette.black,
      fontSize: 14,
      lineHeight: '17px',
      position: 'relative',
      '& a': {
        color: theme.palette.red,
        fontSize: 'inherit',
        lineHeight: 'inherit',
        transition: 'color 300ms ease',
        '&:hover': theme.mixins.hover({
          color: theme.palette.pink
        })
      },
      '&:first-child::before': {
        content: '"—"',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -15,
        margin: 'auto'
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
        lineHeight: '19px',
      }
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 'calc(66% - 60px)'
    }
  },
}))

export default ({ font, setCurrentFont }) => {
  const classes = useStyles()
  font = font || { author: {} }
  let src = null
  let text = null
  if (font.author.image) {
    src = `/fonts/designers/${font.author.image}`
  }
  else {
    text = 'DUH'
  }
  return (
    <div className={classes.Author}>
      <Detail title="SUGGESTED BY">
        <div className={classes.Author_Container}>
          <div className={classes.Author_Image}>
            <Avatar border size={180} src={src} text={text} />
          </div>
          <div className={classes.Author_Quote}>
            <div className={classes.Author_Quote_Icon} style={{ backgroundImage: `url(/font-modal/quote-icon.svg)` }} />
            <p {...fontProps('Lato')} className={classes.Author_Quote_Text}>{font.author.quote}”</p>
            <div className={classes.Author_Quote_Designer}>
              <p {...fontProps('Lato', 'Bold')}>{font.author.name}</p>
              {font.author.company && <p {...fontProps('Lato')} dangerouslySetInnerHTML={{__html: font.author.company}} />}
              {font.author.website && <p {...fontProps('Lato')}><a href={font.author.website} target="_blank" rel="noopener noreferrer">Profile</a></p>}
            </div>
          </div>
        </div>
      </Detail>
    </div>
  )
}
