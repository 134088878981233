import axios from "axios"
import isDev from './is-dev'

const BASE_URL = isDev
  ? 'http://localhost:5001/all-i-font-for-christmas-2024/us-central1'
  : 'https://us-central1-all-i-font-for-christmas-2024.cloudfunctions.net'

export const getCurrentTime = async next => {
  try {
    const res = await axios.get(`${BASE_URL}/current_time`)
    next(null, res)
  }
  catch(e) {
    next(e)
  }
}

export const getFonts = async next => {
  try {
    const now = new Date()
    const res = await axios.get(`${BASE_URL}/fonts?tz=${now.getTimezoneOffset()}`)
    next(null, res)
  }
  catch(e) {
    next(e)
  }
}

export const sendVisitorEmail = async (data, next) => {
  if (!data) {
    return next(new Error('No data provided'))
  }
  try {
    const res = await axios.post(`${BASE_URL}/contact`, data)
    next(null, res)
  }
  catch(e) {
    next(e)
  }
}
