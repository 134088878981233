import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import SocialIcons from '../SocialIcons'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import { ReactComponent as Logo } from '../../../svg/footer-logo.svg'
import { fontProps } from '../../../helpers/fonts'

const LW_URL = 'https://www.lydiamariedesign.com/'
const ZF_URL = 'https://zak-fisher.com'

const useStyles = makeStyles(theme => ({
  Footer: {
    background: theme.palette.purple,
    display: 'flex',
    alignItems: 'center',
    // minHeight: 320
  },
  Footer_Container: {
    // border: '1px solid white',
    height: '100%',
    position: 'relative',
    paddingTop: 30,
    paddingBottom: 60,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 40,
    },
  },
  Footer_Logo: {
    // border: '1px solid',
    height: theme.vars.logoHeight,
    width: theme.vars.logoWidth,
    display: 'flex',
    alignItems: 'center',
  },
  Footer_Social: {
    // border: '1px solid',
    height: theme.vars.logoHeight,
    width: theme.vars.logoWidth + 10,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      left: -25
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      left: 25
    }
  },
  Footer_Text: {
    paddingTop: 20,
    '& p': {
      fontSize: 16,
      lineHeight: '22px',
      margin: 0,
      cursor: 'default',
    },
    '& a, & span': {
      color: theme.palette.white,
      textDecoration: 'underline',
      cursor: 'pointer',
      transition: 'color 300ms ease',
      '&:hover': theme.mixins.hover({
        color: theme.palette.pink
      })
    }
  },
}))

export default () => {
  const classes = useStyles()
  return (
    <footer className={classes.Footer}>
      <Container className={classes.Footer_Container}>
        <Grid item xs={12} sm={6} className={classes.Footer_Logo}>
          <Logo />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.Footer_Social}>
          <SocialIcons />
        </Grid>
        <Grid item xs={12} className={classes.Footer_Text}>
          <p {...fontProps('Lato')}>All I Font for Christmas 2019</p>
          <p {...fontProps('Lato')}>Design by <a href={LW_URL} target="_blank" rel="noopener noreferrer">Lydia Weber</a>, Development by <a href={ZF_URL} target="_blank" rel="noopener noreferrer">Zak Fisher</a></p>
          <p {...fontProps('Lato')}><span onClick={() => events.dispatch(actions.SHOW_CONTACT_MODAL)}>Contact the creators</span></p>
        </Grid>
      </Container>
    </footer>
  )
}

