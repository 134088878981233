import React from "react"
import get from 'lodash.get'
import { makeStyles } from '@material-ui/core/styles'
import isTouchScreen from '../../../helpers/is-touch-screen'
import cache from '../../../helpers/cache'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import lottie from 'lottie-web'
import { ReactComponent as Close } from '../../../svg/close-icon.svg'
import SocialIcons from '../SocialIcons'
import blackLogo from '../../../../static/logo/logo-black.json'
import redLogo from '../../../../static/logo/logo-red.json'
import { DEBUG_SCROLLMAGIC } from '../../../../functions/env'

const useStyles = makeStyles(theme => ({
  Navbar: {
    // border: '1px solid',
    height: theme.vars.logoHeight,
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 101,
    '&.black': {
      background: theme.palette.black,
      '& > span': {
        color: theme.palette.black
      },
      [theme.breakpoints.up('sm')]: {
        background: 'transparent',
        pointerEvents: 'none',
      }
    },
    '&.red': {
      background: theme.palette.red,
      '& > span': {
        color: theme.palette.red
      }
    }
  },
  Navbar_Logo: {
    // border: '1px solid',
    height: theme.vars.logoHeight,
    width: theme.vars.logoWidth,
    position: 'absolute',
    top: 0,
    left: -3,
    '&[data-color="black"]': {
      pointerEvents: 'none',
      opacity: 0,
    }
  },
  Navbar_LogoHotSpot: {
    // border: '1px solid red',
    height: theme.vars.logoHeight,
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 95,
    zIndex: 1,
    '&.expanded': {
      width: theme.vars.logoWidth
    },
    '&[data-color="black"]': {
      pointerEvents: 'none',
      opacity: 0,
    }
  },
  Navbar_LogoDate: {
    // border: '1px solid red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Bree Serif',
    fontWeight: 400,
    fontSize: 21,
    position: 'absolute',
    pointerEvents: 'none',
    top: 2,
    bottom: 0,
    left: 36,
    width: 20,
    margin: 'auto',
    opacity: 1,
    transition: 'opacity 100ms ease',
    '&.hide': {
      opacity: 0
    }
  },
  Navbar_Social: {
    height: theme.vars.logoHeight,
    width: theme.vars.logoWidth + 10,
    background: theme.palette.black,
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    pointerEvents: 'none',
  },
  Navbar_Close: {
    // border: '1px solid',
    position: 'absolute',
    height: theme.vars.logoHeight,
    width: theme.vars.logoHeight,
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      cursor: 'pointer',
      height: '40%',
      width: '40%',
      '& path': {
        fill: theme.palette.white,
        transition: 'fill 300ms ease'
      },
      '&:hover': theme.mixins.hover({
        '& path': {
          fill: theme.palette.pink
        }
      })
    }
  },
}))

let isExpanded = false

export default ({ controller, black, red, social, onClose }) => {
  const classes = useStyles()
  let color = ''
  if (black) color = 'black'
  if (red) color = 'red'
  const date = get(cache.get('fonts'), 'total', '')

  return (
    <header className={`${classes.Navbar} ${color}`} ref={ref => {
      init(ref, classes, controller, color)
    }}>
      <div className={classes.Navbar_Logo} data-color={color} />
      <div className={classes.Navbar_LogoHotSpot} data-color={color} onClick={() => {
        if (isTouchScreen && !isExpanded) return
        if (onClose) onClose()
        scrollToIntro()
      }} />
      <span className={classes.Navbar_LogoDate}>{date}</span>
      {social && (
        <div className={classes.Navbar_Social}>
          <SocialIcons />
        </div>
      )}
      {onClose && (
        <div className={classes.Navbar_Close}>
          <Close onClick={onClose} />
        </div>
      )}
    </header>
  )
}

const init = (ref, classes, controller, color) => window.requestAnimationFrame(() => {
  const { ScrollMagic, TimelineMax } = window
  if (!ref) return

  // Elements
  const logo = ref.querySelector(`.${classes.Navbar_Logo}`)
  const logoHotSpot = ref.querySelector(`.${classes.Navbar_LogoHotSpot}`)
  const logoDate = ref.querySelector(`.${classes.Navbar_LogoDate}`)
  const socialIcons = ref.querySelector(`.${classes.Navbar_Social}`)

  // Logo Hover State
  if (!cache.get(`animation.logo.${color}`)) {
    const animation = lottie.loadAnimation({
      container: logo,
      renderer: 'svg',
      autoplay: false,
      animationData: color === 'red' ? redLogo : blackLogo
    })
    animation.addEventListener('complete', () => {
      isExpanded = animation.currentFrame > 0
    })
    animation.addEventListener('enterFrame', () => {
      if (animation.playDirection === 1) {
        logoHotSpot.classList.add('expanded')
      }
      else {
        logoHotSpot.classList.remove('expanded')
      }
      if (Math.floor(animation.currentFrame) <= 10) {
        logoDate.classList.remove('hide')
      }
      else {
        logoDate.classList.add('hide')
      }
    })
    cache.set(`animation.logo.${color}`, animation)

    if (isTouchScreen) {
      let direction = -1
      logoHotSpot.addEventListener('touchstart', () => {
        direction *= -1
        animation.setDirection(direction)
        animation.play()
      })
      events.on('HIDE_FONT_MODAL', () => {
        direction = -1
        animation.setDirection(direction)
        animation.play()
      })
      events.on('SCROLL_START', () => {
        direction = -1
        animation.setDirection(direction)
        animation.play()
      })
    }
    else {
      logoHotSpot.addEventListener('mouseenter', () => {
        animation.setDirection(1)
        animation.play()
      })
      logoHotSpot.addEventListener('mouseleave', () => {
        animation.pause()
        animation.setDirection(-1)
        animation.play()
      })
    }
  }

  // Add ScrollMagic Scene to black logo
  if (!controller || !ScrollMagic) return

  if (color === 'black' && !cache.get('scene.logo.black')) {
    const scene = new ScrollMagic.Scene({
      duration: '30%',
      offset: 0.75 * window.innerHeight,
    })

    events.on(actions.RESIZE_STOP, () => {
      scene.offset(0.75 * window.innerHeight)
      controller.updateScene(scene, true)
    })

    const tl = new TimelineMax()
    tl.fromTo([logoHotSpot, logo, socialIcons], 1,
      { opacity: 0, pointerEvents: 'none' },
      { opacity: 1, pointerEvents: 'all', overwrite: false, immediateRender: false }
    , 0)
    scene.setTween(tl)

    if (DEBUG_SCROLLMAGIC) scene.addIndicators()

    controller.addScene(scene)
    cache.set('scene.logo.black', scene)
  }
})

const scrollToIntro = () => {
  if (!window.TweenMax) return
  window.TweenMax.to(window, 1.5, {
    scrollTo: {
      y: 0,
      autoKill: false
    },
    ease: 'circ.out'
  })
}
