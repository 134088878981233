const texts = []

const text = (name, config) => texts.push({
  name,
  ...config,
})

text(1, {
  size: [10, 1],
  coords: {
    mobile: [0, 4],
    desktop: [0, 4],
  },
  scroll: {
    mobile: ['1%', '-65%'],
    desktop: ['1%', '-55%']
  }
})

text(2, {
  size: [11, 1],
  coords: {
    mobile: [0, 18],
    desktop: [0, 11],
  },
  scroll: {
    mobile: ['1%', '-72%'],
    desktop: ['1%', '-53.5%']
  }
})

text(3, {
  size: [7, 1],
  coords: {
    mobile: [0, 34],
    desktop: [3, 20],
  },
  scroll: {
    mobile: ['1%', '-52%'],
    desktop: ['1%', '-52%']
  }
})

text(4, {
  size: [6, 1],
  coords: {
    mobile: [0, 49],
    desktop: [3, 27],
  },
  scroll: {
    mobile: ['1%', '-42.5%'],
    desktop: ['1%', '-42.5%']
  }
})

export default texts
