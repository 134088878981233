import React from "react"
import get from 'lodash.get'
import { makeStyles } from '@material-ui/core/styles'
import days from '../../../data/tiles/days'
import animations from '../../../data/tiles/animations'
import graphics from '../../../data/tiles/graphics'
import text from '../../../data/tiles/text'
import TileDay from './TileDay'
import TileAnimation from './TileAnimation'
import TileGraphic from './TileGraphic'
import TileText from './TileText'

// Height = (100 / columns) * rows
const mobileHeight = `${(100 / 3) * 55}vw`
const desktopHeight = `${(100 / 6) * 31}vw`

// Border Radius = (100 / (columns * 2)) * rows
const mobileBorderRadius = `${(100 / 6)}vw`
const desktopBorderRadius = `${(100 / 12)}vw`

const useStyles = makeStyles(theme => ({
  Tiles: {
    // border: '1px solid red',
    position: 'absolute',
    top: 0,
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: mobileHeight
    },
    [theme.breakpoints.up('md')]: {
      height: desktopHeight
    },
  },
  Tiles_Tile: {
    position: 'absolute',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: '100%',
      width: '100%'
    },
    '& > p': {
      position: 'absolute',
      zIndex: 1,
      background: 'rgba(0,0,0,0.8)',
      margin: 0,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'white'
    }
  }
}))

export default props => {
  const classes = useStyles()
  const { mobile } = props
  return (
    <div className={classes.Tiles}>
      {text.map((tile, i) => (
        <TileText key={`text-${i}`}
          {...props}
          className={classes.Tiles_Tile}
          style={getStyle({ mobile, ...tile })}
          tile={tile}
        />
      ))}
      {graphics.map((tile, i) => (
        <TileGraphic key={`graphics-${i}`}
          {...props}
          className={classes.Tiles_Tile}
          style={getStyle({ mobile, ...tile })}
          tile={tile}
        />
      ))}
      {animations.map((tile, i) => (
        <TileAnimation key={`animations-${i}`}
          {...props}
          className={classes.Tiles_Tile}
          style={getStyle({ mobile, ...tile })}
          tile={tile}
        />
      ))}
      {days.map((tile, i) => (
        <TileDay key={`days-${i}`}
          {...props}
          className={classes.Tiles_Tile}
          style={getStyle({ mobile, ...tile })}
          tile={tile}
        />
      ))}
    </div>
  )
}

const getStyle = ({ mobile, size, coords, corners }) => {
  const [ width, height ] = size
  const [ left, top ] = coords[mobile ? 'mobile' : 'desktop']
  const borderRadius = mobile ? mobileBorderRadius : desktopBorderRadius
  const columns = mobile ? 3 : 6
  const cellSize = window.innerWidth / columns
  const percent = 100 * (cellSize / window.innerWidth)
  const style = {
    height: `${height * percent}vw`,
    width: `${width * percent}vw`,
    top: `${top * percent}vw`,
    left: `${left * percent}vw`,
  }
  if (get(corners, 'tl')) style.borderTopLeftRadius = borderRadius
  if (get(corners, 'tr')) style.borderTopRightRadius = borderRadius
  if (get(corners, 'bl')) style.borderBottomLeftRadius = borderRadius
  if (get(corners, 'br')) style.borderBottomRightRadius = borderRadius
  return style
}
