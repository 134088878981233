import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { fontProps } from '../../../helpers/fonts'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import Navbar from '../Navbar'
import Button from '../Button'

const useStyles = makeStyles(theme => ({
  Header: {
    background: theme.palette.red,
    width: '100%',
    paddingTop: 90,
    paddingBottom: 60
  },
  Header_Container: {
    // border: '1px solid',
    '& *': {
      margin: 0
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    }
  },
  Header_Date: {
    // border: '1px solid',
    marginBottom: 20,
    '& > div': {
      border: '3px solid white',
      borderRadius: '100%',
      height: 180,
      width: 180,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 20,
      '& > span': {
        fontSize: 97,
        lineHeight: '118px'
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '33%',
      '& > div': {
        height: 'unset',
        width: '100%',
        maxWidth: 250,
        '&:after': {
          content: '""',
          display: 'block',
          paddingTop: '100%'
        }
      }
    }
  },
  Header_Text: {
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    }
  },
  Header_Title: {
    '& h1': {
      fontSize: 50,
      lineHeight: '65px',
      [theme.breakpoints.up('md')]: {
        fontSize: 72,
        lineHeight: '120px',
      }
    },
  },
  Header_Subtitle: {
    marginBottom: 30,
    padding: '12px 0',
    '& h2': {
      fontSize: 18,
      lineHeight: '24px',
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      '& h2': {
        fontSize: 22,
        fontWeight: 700,
        lineHeight: '28px',
        marginBottom: 50,
      }
    }
  },
  Header_Button: {},
}))

export default ({ font }) => {
  const classes = useStyles()
  font = font || {}
  return (
    <div className={classes.Header}>
      <Navbar red onClose={() => events.dispatch(actions.HIDE_FONT_MODAL)} />
      <Container className={classes.Header_Container}>
        <div className={classes.Header_Date}>
          <div>
            <span {...fontProps(font.trial ? 'Lato' : font.name)}>{font.date}</span>
          </div>
        </div>
        <div className={classes.Header_Text}>
          <div className={classes.Header_Title}>
            <h1 {...fontProps(font.name, font.demoStyle || 'Regular')}>{font.name}</h1>
          </div>
          <div className={classes.Header_Subtitle}>
            <h2 {...fontProps('Lato')}>{font.subtitle}</h2>
          </div>
          <div className={classes.Header_Button}>
            <Button black text="Download Font"
              onClick={() => {
                if (font.provider === 'google') {
                  events.dispatch(actions.SHOW_DOWNLOAD_MODAL)
                }
                else {
                  window.open(font.provider, 'blank')
                }
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
