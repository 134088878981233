import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import cache from '../../../helpers/cache'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import Grid from '@material-ui/core/Grid'

import { DEBUG_SCROLLMAGIC } from '../../../../functions/env'

const dotWidth = 4
const dotOffset = -dotWidth / 2

const useStyles = makeStyles(theme => ({
  Background: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    pointerEvents: 'none',
  },
  Background_Frame: {
    border: `${dotWidth / 2}px solid ${theme.palette.black}`,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  Background_Cell: {
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    },
    '&::after': {
      content: '""',
      display: 'block',
      height: dotWidth,
      width: dotWidth,
      borderRadius: '100%',
      background: theme.palette.white,
      position: 'absolute',
      left: dotOffset,
      top: dotOffset,
    },
  },
}))

export default ({ mobile, controller }) => {
  const classes = useStyles()
  const [ scene, setScene ] = React.useState(null)
  const [ height, setHeight ] = React.useState(getHeight(mobile))

  // Add ScrollMagic
  React.useEffect(() => {
    if (!controller || scene) return
    addScene(controller, classes, setScene)
  }, [controller, scene, classes])

  // Add Resize Handler
  React.useEffect(() => {
    const resizeKey = events.on(actions.RESIZE, () => {
      const newHeight = getHeight(mobile)
      if (height === newHeight) return
      setHeight(newHeight)
    })
    return () => events.off(resizeKey)
  }, [mobile, height])

  return (
    <Grid container className={classes.Background} style={{ height }}>
      {(() => {
        const cells = []
        while (cells.length < 99) {
          cells.push(<Grid container item xs={4} md={2} key={cells.length} className={classes.Background_Cell} />)
        }
        return cells
      })()}
      <div className={classes.Background_Frame} />
    </Grid>
  )
}

const getHeight = mobile => {
  const columns = mobile ? 3 : 6
  const cellSize = window.innerWidth / columns
  const percent = 100 * (cellSize / window.innerWidth)
  let rows = 0
  let rowHeight = 0
  while (rowHeight < window.innerHeight) {
    rowHeight += cellSize
    rows++
  }
  return `${rows * percent}vw`
}

const addScene = (controller, classes, setScene) => {
  const background = window.document.querySelector(`.${classes.Background}`)
  const tiles = window.document.querySelector(`.${classes.Background} + div`)

  const getDuration = () => tiles.clientHeight - background.clientHeight

  const scene = new window.ScrollMagic.Scene({
    triggerHook: 0,
    triggerElement: background,
    offset: 0,
    duration: getDuration(),
  })
  scene.setPin(background)

  events.on(actions.RESIZE, () => {
    window.requestAnimationFrame(() => {
      scene.duration(getDuration())
      controller.update(scene, true)
    })
  })

  if (DEBUG_SCROLLMAGIC) scene.addIndicators()

  setScene(scene)
  controller.addScene(scene)
  cache.set('scene.grid', scene)
}
