import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fontProps } from '../../../helpers/fonts'
import { sendVisitorEmail } from '../../../helpers/api'
import Button from '../Button'

const useStyles = makeStyles(theme => ({
  ContactForm: {
    position: 'relative',
    '& p': {
      margin: 0,
      color: theme.palette.red,
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '17px',
      height: 30,
      paddingTop: 5
    }
  },
  ContactForm_RequestOverlay: {
    // border: '1px solid red',
    // background: 'rgba(255,255,255,0.9)',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  ContactForm_Success: {
    // border: '1px solid red',
    background: 'white',
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > div': {
      backgroundImage: 'url(/font-modal/contact-success.svg)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: 107,
      width: 107
    },
    '& h1': {
      color: theme.palette.green,
      fontSize: 38,
      lineHeight: '46px',
      [theme.breakpoints.up('sm')]: {
        width: '80%',
        fontSize: 54,
        lineHeight: '73px',
      }
    }
  },
  ContactForm_Field: {
    // paddingBottom: 30,
    '& label': {
      color: theme.palette.black,
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '17px'
    },
    '&[disabled]': {
      opacity: 0.5
    }
  },
  ContactForm_Input: {
    // border: '1px solid black',
    width: '100%',
    '& > div:before': {
      borderBottom: `3px solid ${theme.palette.black} !important`,
    },
    '& > div:after': {
      borderBottom: `3px solid ${theme.palette.purple}`,
    },
    '& > div.Mui-error:after': {
      borderBottom: `3px solid ${theme.palette.red}`,
    },
    '& input': {
      paddingBottom: 10,
    },
    '& textarea': {
      paddingBottom: 5,
    },
    '& input, & textarea': {
      color: theme.palette.purple,
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '22px',
      '&::placeholder': {
        color: '#CCCCCC'
      },
    },
  },
  ContactForm_Submit: {
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    '& p': {
      paddingLeft: 20
    }
  },
  ContactForm_ButtonInner: {
    width: 63,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.black,
    '& circle': {
      stroke: theme.palette.black
    }
  }
}))

/* eslint-disable no-control-regex */
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export default ({ isOpen, font }) => {
  const classes = useStyles()

  const [ submitted, setSubmitted ] = React.useState(false)
  const [ requestInProgress, setRequestInProgress ] = React.useState(false)
  const [ errors, setErrors ] = React.useState({})
  const [ name, setName ] = React.useState('')
  const [ email, setEmail ] = React.useState('')
  const [ message, setMessage ] = React.useState('')
  const [ typeface, setTypeface ] = React.useState('')

  const submit = e => {
    if (e) e.preventDefault()
    if (requestInProgress) return

    const errors = {}
    if (!name.trim().length) errors.name = 'Please enter a name'
    if (!emailRegex.test(email)) errors.email = 'Please enter a valid email address'
    if (!message.replace(/↵/g, '').trim().length) errors.message = 'Please enter a message'
    setErrors(errors)
    if (Object.keys(errors).length) return

    setRequestInProgress(true)

    const data = { name, email, message, typeface }
    sendVisitorEmail(data, (err, res) => {
      setRequestInProgress(false)
      if (err) {
        setErrors({ form: 'Unable to send message' })
        return
      }
      setSubmitted(true)
    })
  }

  return (
    <form className={classes.ContactForm} noValidate autoComplete="off" onSubmit={submit}>
      {requestInProgress && <div className={classes.ContactForm_RequestOverlay} />}
      {submitted && (
        <div className={classes.ContactForm_Success}>
          <div />
          <h1 {...fontProps('Bree Serif')}>Thanks! We’ll be in touch soon.</h1>
        </div>
      )}
      <div className={classes.ContactForm_Field} disabled={requestInProgress}>
        <label>Your name*</label>
        <TextField
          error={!!errors.name}
          className={classes.ContactForm_Input}
          onChange={e => {
            setName(e.target.value)
            setErrors({ ...errors, form: '', name: '' })
          }}
          placeholder="Helen Helvetica"
        />
        <p>{errors.name}</p>
      </div>
      <div className={classes.ContactForm_Field} disabled={requestInProgress}>
        <label>Your email*</label>
        <TextField
          error={!!errors.email}
          className={classes.ContactForm_Input}
          onChange={e => {
            setEmail(e.target.value)
            setErrors({ ...errors, form: '', email: '' })
          }}
          placeholder="santa@northpolefoundry.com"
        />
        <p>{errors.email}</p>
      </div>
      <div className={classes.ContactForm_Field} disabled={requestInProgress}>
        <label>Message*</label>
        <TextField
          error={!!errors.message}
          multiline
          className={classes.ContactForm_Input}
          onChange={e => {
            setMessage(e.target.value)
            setErrors({ ...errors, form: '', message: '' })
          }}
          placeholder="Hi there…"
        />
        <p>{errors.message}</p>
      </div>
      <div className={classes.ContactForm_Field} disabled={requestInProgress}>
        <label>And most importantly, what’s your type?</label>
        <TextField
          className={classes.ContactForm_Input}
          onChange={e => setTypeface(e.target.value)}
          placeholder="A typeface you think is pretty jolly"
        />
      </div>
      <div className={classes.ContactForm_Submit}>
        <Button black submit text={(
          <div className={classes.ContactForm_ButtonInner}>
            { requestInProgress ? <CircularProgress size={30} /> : 'Submit' }
          </div>
        )} onClick={submit} />
        <p>{errors.form}</p>
      </div>
    </form>
  )
}
