import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import cache from '../../../helpers/cache'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import isMobile from '../../../helpers/is-mobile'
import { DEBUG_CALENDAR, DEBUG_SCROLLMAGIC } from '../../../../functions/env'

const useStyles = makeStyles(theme => ({
  TileText: {
    border: DEBUG_CALENDAR ? '1px solid green' : 'none',
    pointerEvents: 'none',
    '& > div': {
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      width: '100%',
    }
  },
}))

export default ({ controller, className, style, textStyle, tile }) => {
  const classes = useStyles()
  return (
    <div className={classes.TileText + ' ' + className} style={style} ref={initScene(controller, tile)}>
      {DEBUG_CALENDAR && <p>text-{tile.name}</p>}
      <div style={{
        ...textStyle,
        backgroundImage: `url(/calendar/text/${tile.name}.svg)`
      }} />
    </div>
  )
}

const rendered = {}
const initScene = (controller, tile) => ref => {
  const { ScrollMagic, TimelineMax } = window
  if (!ref || !controller || cache.get(`scene.calendar.text${tile.name}`)) return
  if (rendered[tile.name]) return
  rendered[tile.name] = true

  const getOpts = () => {
    const { height, top } = ref.getBoundingClientRect()
    const headerHeight = 70
    const mobile = isMobile()

    let duration = window.innerHeight - (mobile ? headerHeight : 0) + height

    const offset = window.pageYOffset - window.innerHeight + top

    if (tile.name === 4 && !mobile && window.innerHeight >= window.innerWidth) {
      duration = window.document.body.offsetHeight - window.innerHeight - offset
    }
    if (duration < 0) duration = 0

    return {
      duration,
      offset,
      triggerHook: 1
    }
  }

  const getTween = () => {
    const tl = new TimelineMax({
      onUpdate: () => {
        const progress = tl.progress()
        const lockItUp = progress > 0 && progress < 1
        cache.set('scrolling-text', lockItUp)
      },
    })
    const [ fromX, toX ] =  tile.scroll[isMobile() ? 'mobile' : 'desktop']
    tl.fromTo(ref, 1,
      { x: fromX, overwrite: false, immediateRender: false },
      { x: toX, ease: window.Power2.easeInOut, overwrite: false, immediateRender: false }
      // { x: toX, ease: window.Linear.easeNone }
    , 0)
    return tl
  }

  const scene = new ScrollMagic.Scene(getOpts())
  scene.setTween(getTween())

  const resize = () => {
    const { duration, offset } = getOpts()
    scene.duration(duration)
    scene.offset(offset)
    scene.setTween(getTween())
    controller.updateScene(scene, true)
  }
  events.on(actions.RESIZE_STOP, resize)
  window.requestAnimationFrame(resize)

  if (DEBUG_SCROLLMAGIC) scene.addIndicators()

  controller.addScene(scene)
  cache.set(`scene.calendar.text${tile.name}`, scene)
}
