// https://gist.github.com/chrisjlee/5196139

const url = 'https://www.allifontforchristmas.com/'
const title = 'All I Font for Christmas'

export const getFacebookUrl = () => {
  return `http://www.facebook.com/sharer.php?u=${url}&p[title]=${title}`
}

export const getTwitterUrl = () => {
  return `http://twitter.com/share?text=${title}&url=${url}`
}

export const getPinterestUrl = () => {
  const image = 'https://www.allifontforchristmas.com/social/800x800.png'
  return [
    'https://www.pinterest.com/pin/create/button/?',
    `url=${encodeURIComponent(url)}`,
    // '&guid=kd1dttaGL_5R',
    `&media=${encodeURIComponent(image)}`,
    // '&h=1102',
    // '&w=735',
    `&description=${encodeURIComponent(title)}`,
  ].join('&')
}
// https://css-tricks.com/using-pinterest-data-attributes-and-meta-tags/
// https://codepen.io/team/css-tricks/pen/ZGagbg?editors=1000

export const FB_SHARE_URL = getFacebookUrl()
export const TW_SHARE_URL = getTwitterUrl()
export const PI_SHARE_URL = getPinterestUrl()
