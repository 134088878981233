import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as Facebook } from '../../../svg/facebook-icon.svg'
import { ReactComponent as Twitter } from '../../../svg/twitter-icon.svg'
import { ReactComponent as Pinterest } from '../../../svg/pinterest-icon.svg'
import { ReactComponent as Copy } from '../../../svg/copy-icon.svg'
import {
  FB_SHARE_URL,
  TW_SHARE_URL,
  PI_SHARE_URL,
} from '../../../helpers/social-media'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const useStyles = makeStyles(theme => ({
  SocialIcons: {
    // border: '1px solid',
    height: theme.vars.logoHeight,
    width: theme.vars.logoWidth + 10,
    display: 'flex',
    padding: '0 15px',
    flexWrap: 'wrap',
    '& > div': {
      flexGrow: 1,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& a, & span': {
        // border: '1px solid red',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        height: 30,
        width: 30,
        '& svg': {
          height: '100%',
          width: '100%',
        },
        '& svg *': {
          fill: theme.palette.white,
          transition: 'fill 300ms ease'
        },
        '&:hover': theme.mixins.hover({
          '& svg *': {
            fill: theme.palette.pink
          },
        })
      },
    },
    '&.copy': {
      width: '100%',
      '& > div': {
        position: 'relative',
        '& a, & span': {
          height: 40,
          width: 40,
          '& svg *': {
            fill: theme.palette.black
          }
        },
        '& p': {
          margin: 0,
          fontSize: 12,
          fontFamily: 'Lato',
          fontWeight: 700,
          color: theme.palette.black,
          position: 'absolute',
          pointerEvents: 'none',
          textAlign: 'center',
          bottom: -10,
          whiteSpace: 'nowrap'
        }
      }
    }
  },
}))

export default ({ copy }) => {
  const classes = useStyles()

  const [ copied, setCopied ] = React.useState(false)

  React.useEffect(() => {
    events.on(actions.HIDE_DOWNLOAD_MODAL, () => setCopied(false))
  }, [])

  let className = classes.SocialIcons
  if (copy) className += ' copy'
  return (
    <div className={className}>
      <div>
        <a href={TW_SHARE_URL} target="_blank" rel="noopener noreferrer">
          <Twitter />
        </a>
      </div>
      <div>
        <a href={FB_SHARE_URL} target="_blank" rel="noopener noreferrer">
          <Facebook />
        </a>
      </div>
      <div>
        <a href={PI_SHARE_URL} target="_blank" rel="noopener noreferrer">
          <Pinterest />
        </a>
      </div>
      {copy && (
        <div>
          <CopyToClipboard text="https://www.allifontforchristmas.com" onCopy={() => setCopied(true)}>
            <span><Copy /></span>
          </CopyToClipboard>
          <p>{copied ? 'Copied!' : 'Copy site link'}</p>
        </div>
      )}
    </div>
  )
}
