import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'
import Detail from './Detail'

const useStyles = makeStyles(theme => ({
  About: {
    height: '100%',
    '& *': {
      color: theme.palette.black,
      fontSize: 14,
      lineHeight: '20px',
      margin: 0,
    },
    '& p': {
      fontFamily: 'Lato',
      fontWeight: 400,
      '&:not(:last-child)': {
        marginBottom: 20,
      }
    },
    '& a': {
      color: theme.palette.red,
      transition: 'color 300ms ease',
      '&:hover': theme.mixins.hover({
        color: theme.palette.pink
      })
    },
    [theme.breakpoints.up('md')]: {
      borderLeft: `3px solid ${theme.palette.pink}`,
      borderRight: `3px solid ${theme.palette.pink}`,
      paddingLeft: 32,
      paddingRight: 32
    }
  },
}))

export default ({ font, setCurrentFont }) => {
  const classes = useStyles()
  font = font || {}
  return (
    <div className={classes.About}>
      <Detail title="ABOUT">
        <p {...fontProps('Lato')} dangerouslySetInnerHTML={{__html: font.about}} />
      </Detail>
    </div>
  )
}
