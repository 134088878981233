import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Header from './Header'
import Background from './Background'
import Tiles from './Tiles'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'

const useStyles = makeStyles(theme => ({
  Calendar: {},
  Calendar_Content: {
    position: 'relative',
    '& > .scrollmagic-pin-spacer': {
      background: theme.palette.black
    }
  },
}))

export default props => {
  const classes = useStyles()
  const [ style, setStyle ] = React.useState({
    paddingTop: window.innerHeight * 1.2
  })

  // Add Resize Handler
  React.useEffect(() => {
    const resizeKey = events.on(actions.RESIZE, () => {
      setStyle({ paddingTop: window.innerHeight * 1.2 })
    })
    return () => events.off(resizeKey)
  }, [style])

  return (
    <div className={classes.Calendar} style={style}>
      <Header />
      <div className={classes.Calendar_Content}>
        <Background {...props} />
        <Tiles {...props} />
      </div>
    </div>
  )
}
