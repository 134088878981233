const graphics = []

const graphic = (name, config) => graphics.push({
  name,
  ...config,
})

graphic(3, {
  size: [1, 1],
  coords: {
    mobile: [1, 3],
    desktop: [1, 3],
  },
  corners: {
    bl: 1
  }
})

graphic(8, {
  size: [1, 1],
  coords: {
    mobile: [2, 18],
    desktop: [4, 11],
  },
  corners: {}
})

graphic(10, {
  size: [1, 1],
  coords: {
    mobile: [2, 23],
    desktop: [2, 13],
  },
  corners: {}
})

graphic(17, {
  size: [1, 1],
  coords: {
    mobile: [1, 42],
    desktop: [1, 23],
  },
  corners: {}
})

graphic(25, {
  size: [2, 2],
  coords: {
    mobile: [0, 35],
    desktop: [0, 19],
  },
  corners: {}
})

export default graphics
