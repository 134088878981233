import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'
import Detail from './Detail'

const useStyles = makeStyles(theme => ({
  Characters: {
    '& p': {
      color: theme.palette.black,
      fontSize: 24,
      lineHeight: '35px',
      margin: 0,
      wordBreak: 'break-all',
      maxWidth: 800,
    },
    [theme.breakpoints.up('md')]: {
      width: '67%',
      paddingLeft: 32,
      '& p': {
        fontSize: 38,
        lineHeight: '56px'
      }
    }
  },
}))

const defaultChars = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890‘?’“!”(%)[#]{@}/&\\<-+÷×=>®©$€£¥¢:;,.*`

export default ({ font, setCurrentFont }) => {
  const classes = useStyles()
  font = font || {}
  const chars = font.chars || defaultChars
  return (
    <div className={classes.Characters}>
      <Detail title="CHARACTERS">
        <p {...fontProps(font.name, font.demoStyle || 'Regular')}>{chars}</p>
      </Detail>
    </div>
  )
}
