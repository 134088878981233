import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'
import Detail from './Detail'
import Avatar from '../Avatar'

const useStyles = makeStyles(theme => ({
  Usage: {
    '& p': {
      color: theme.palette.black,
      fontSize: 14,
      lineHeight: '19px',
      margin: 0,
      maxWidth: 260
    },
    '& div + p': {
      marginTop: 20
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 32,
    }
  },
}))

export default ({ font, setCurrentFont }) => {
  const classes = useStyles()
  font = font || {}

  let text = font.usage
  if (font.provider === 'google') {
    text = `These are the countries where ${font.name} is the most popular.`
  }

  return (
    <div className={classes.Usage}>
      <Detail title="USAGE">
        {font.date && (
          <Avatar
            size={250}
            src={`/font-modal/usage/${font.date}.png`}
            bgSize="contain"
            border={font.provider === 'google'}
            radius={font.provider === 'google' ? null : '0'}
            position="left center"
          />
        )}
        {text && <p {...fontProps('Lato', 'Regular')}>{text}</p>}
      </Detail>
    </div>
  )
}
