import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'

const useStyles = makeStyles(theme => ({
  Detail: {
    padding: '55px 0px',
    width: '100%',
    '& > span:first-child': {
      display: 'block',
      color: theme.palette.green,
      fontSize: 16,
      lineHeight: '19px',
      marginBottom: 20
    },
  },
}))

export default ({ title, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.Detail}>
      <span {...fontProps('Lato', 'Bold')}>{title.toUpperCase()}</span>
      {children}
    </div>
  )
}
