import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Specimen from './Specimen'
import Characters from './Characters'
import Styles from './Styles'
import Sample from './Sample'
import Author from './Author'
import Designers from './Designers'
import About from './About'
import Usage from './Usage'

const useStyles = makeStyles(theme => ({
  Body: {},
  Body_Container: {
    // border: '1px solid blue',
    background: theme.palette.white,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  Body_Border: {
    height: 3,
    width: '100%',
    background: theme.palette.pink
  }
}))

export default props => {
  const classes = useStyles()
  return (
    <div className={classes.Body}>
      {props.mobile ? (
        <>
          <Container className={classes.Body_Container}>
            <Specimen {...props} />
          </Container>
          <div className={classes.Body_Border} />
          <Container className={classes.Body_Container}>
            <Characters {...props} />
          </Container>
        </>
      ) : (
        <Container className={classes.Body_Container}>
          <Specimen {...props} />
          <Characters {...props} />
        </Container>
      )}
      <div className={classes.Body_Border} />
      <Container className={classes.Body_Container}>
        <Styles {...props} />
      </Container>
      <div className={classes.Body_Border} />
      <Container className={classes.Body_Container}>
        <Sample {...props} />
      </Container>
      <div className={classes.Body_Border} />
      <Container className={classes.Body_Container}>
        <Author {...props} />
      </Container>
      <div className={classes.Body_Border} />
      {props.mobile ? (
        <>
          <Container className={classes.Body_Container}>
            <Designers {...props} />
          </Container>
          <div className={classes.Body_Border} />
          <Container className={classes.Body_Container}>
            <About {...props} />
          </Container>
          <div className={classes.Body_Border} />
          <Container className={classes.Body_Container}>
            <Usage {...props} />
          </Container>
        </>
      ) : (
        <Container className={classes.Body_Container}>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Designers {...props} />
            </Grid>
            <Grid item xs={12} md={4}>
              <About {...props} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Usage {...props} />
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  )
}
