const animations = []

const animation = (name, config) => animations.push({
  name,
  ...config,
})

animation(1, {
  size: [1, 1],
  coords: {
    mobile: [0, 0],
    desktop: [1, 0],
  },
  corners: {}
})

animation(2, {
  size: [2, 1],
  coords: {
    mobile: [1, 1],
    desktop: [4, 1],
  },
  corners: {}
})

animation(4, {
  size: [1, 1],
  coords: {
    mobile: [0, 9],
    desktop: [4, 7],
  },
  corners: {}
})

animation(5, {
  size: [1, 1],
  coords: {
    mobile: [2, 13],
    desktop: [5, 8],
  },
  corners: {
    tl: 1,
    tr: 1,
    bl: 1,
    br: 1
  }
})

animation(6, {
  size: [1, 2],
  coords: {
    mobile: [2, 16],
    desktop: [5, 9],
  },
  corners: {}
})

animation(7, {
  size: [1, 1],
  coords: {
    mobile: [0, 15],
    desktop: [0, 10],
  },
  corners: {
    tl: 1
  }
})

animation(9, {
  size: [1, 1],
  coords: {
    mobile: [0, 19],
    desktop: [0, 12],
  },
  corners: {}
})

animation(11, {
  size: [3, 1],
  coords: {
    mobile: [0, 24],
    desktop: [0, 14],
  },
  corners: {}
})

animation(12, {
  size: [1, 1],
  coords: {
    mobile: [1, 27],
    desktop: [1, 15],
  },
  corners: {
    tl: 1
  }
})

animation(13, {
  size: [1, 1],
  coords: {
    mobile: [0, 28],
    desktop: [0, 16],
  },
  corners: {}
})

animation(14, {
  size: [2, 1],
  coords: {
    mobile: [1, 30],
    desktop: [4, 16],
  },
  corners: {}
})

animation(15, {
  size: [3, 1],
  coords: {
    mobile: [0, 33],
    desktop: [3, 19],
  },
  corners: {}
})

animation(16, {
  size: [2, 1],
  coords: {
    mobile: [0, 38],
    desktop: [0, 22],
  },
  corners: {}
})

animation(18, {
  size: [1, 1],
  coords: {
    mobile: [0, 43],
    desktop: [0, 24],
  },
  corners: {}
})

animation(19, {
  size: [1, 1],
  coords: {
    mobile: [2, 44],
    desktop: [2, 25],
  },
  corners: {}
})

animation(20, {
  size: [1, 1],
  coords: {
    mobile: [2, 47],
    desktop: [5, 26],
  },
  corners: {}
})

animation(21, {
  size: [1, 1],
  coords: {
    mobile: [0, 51],
    desktop: [0, 27],
  },
  corners: {}
})

animation(22, {
  size: [3, 1],
  coords: {
    mobile: [0, 48],
    desktop: [3, 28],
  },
  corners: {}
})

animation(23, {
  size: [3, 1],
  coords: {
    mobile: [0, 53],
    desktop: [0, 29],
  },
  corners: {}
})

animation(24, {
  size: [1, 1],
  coords: {
    mobile: [0, 54],
    desktop: [0, 30],
  },
  corners: {}
})

export default animations
