const actions = [
  'RESIZE',
  'RESIZE_START',
  'RESIZE_STOP',
  'SCROLL',
  'SCROLL_START',
  'SCROLL_STOP',
  'SHOW_FONT_MODAL',
  'HIDE_FONT_MODAL',
  'SHOW_DOWNLOAD_MODAL',
  'HIDE_DOWNLOAD_MODAL',
  'SHOW_CONTACT_MODAL',
  'HIDE_CONTACT_MODAL',
  'INTRO_COMPLETE'
]

const actionMap = {}
actions.map(a => actionMap[a] = a)

export default actionMap
