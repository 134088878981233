import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import lottie from 'lottie-web'
import cache from '../../../helpers/cache'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import { DEBUG_CALENDAR } from '../../../../functions/env'
import ANIMATIONS from '../../../data/tiles/animations'

const animations = {}
ANIMATIONS.forEach(a => {
  animations[a.name] = require(`../../../../static/calendar/animations/loop-${a.name}.json`)
})

const useStyles = makeStyles(theme => ({
  TileAnimation: {
    border: DEBUG_CALENDAR ? '1px solid orange' : 'none',
    pointerEvents: 'none',
    '& > div': {
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
    }
  },
}))

export default ({ className, style, tile }) => {
  const classes = useStyles()
  return (
    <div className={classes.TileAnimation + ' ' + className} style={style} ref={initAnimation(tile)}>
      {DEBUG_CALENDAR && <p>animation-{tile.name}</p>}
      <div />
    </div>
  )
}

const inView = ref => {
  const windowHeight = window.innerHeight
  const windowTop = window.pageYOffset
  const windowBottom = windowTop + windowHeight

  const refBounds = ref.getBoundingClientRect()
  const top = windowTop + refBounds.top
  const bottom = windowTop + refBounds.bottom

  const topInView = (top >= windowTop) && (top <= windowBottom)
  const bottomInView = (bottom >= windowTop) && (bottom <= windowBottom)
  const isInView = topInView || bottomInView

  return isInView
}

const initAnimation = tile => ref => {
  if (!ref || ref.querySelector('svg')) return

  const data = animations[tile.name] || null
  if (!data) return

  const animation = lottie.loadAnimation({
    container: ref.querySelector('div'),
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: data
  })
  animation.goToAndStop(animation.totalFrames * 0.25, animation.totalFrames)

  const togglePlay = () => {
    const isInView = inView(ref)
    if (!animation) return
    if (cache.get('scrolling-text')) {
      animation.pause()
      return
    }
    if (isInView && animation.isPaused) animation.play()
    if (!isInView && !animation.isPaused) animation.pause()
  }

  events.on(actions.SCROLL, togglePlay)
  events.on(actions.SCROLL_STOP, () => {
    cache.set('scrolling-text', false)
    togglePlay()
  })
  events.on(actions.RESIZE, togglePlay)

  cache.set(`animation.calendar.animation${tile.name}`, animation)
}
