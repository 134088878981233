import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import { fontProps } from '../../../helpers/fonts'
import isMobile from '../../../helpers/is-mobile'
import events from '../../../helpers/events'
import actions from '../../../helpers/actions'
import Detail from './Detail'

const useStyles = makeStyles(theme => ({
  Sample: {
    position: 'relative',
    width: '100%',
    '& p': {
      color: theme.palette.black,
      margin: 0,
      wordBreak: 'break-word'
    },
  },
  Sample_Slider: {
    // border: '1px solid red',
    width: 200,
    position: 'absolute',
    top: 64,
    transform: 'translateY(-50%)',
    right: 5,
    display: 'flex',
    alignItems: 'center',
    '& > span:first-child': {
      // border: '1px solid black',
      color: theme.palette.red,
      fontSize: 14,
      lineHeight: '17px',
      width: 55,
    },
    [theme.breakpoints.up('sm')]: {
      width: 250,
    }
  }
}))

const useSliderStyles = makeStyles(theme => ({
  root: {
    // border: '1px solid'
  },
  rail: {
    background: theme.palette.softRed
  },
  track: {
    background: theme.palette.red,
  },
  thumb: {
    background: theme.palette.red,
    height: 12,
    width: 12,
  },
  focusVisible: {
    boxShadow: `0px 0px 0px 8px ${theme.palette.softRed} !important`,
    '&:hover': {
      boxShadow: `0px 0px 0px 14px ${theme.palette.softRed} !important`
    }
  },
  active: {
    boxShadow: `0px 0px 0px 8px ${theme.palette.softRed} !important`,
    '&:hover': {
      boxShadow: `0px 0px 0px 8px ${theme.palette.softRed} !important`,
    }
  },
  thumbColorPrimary: {
    '&:hover': {
      boxShadow: `0px 0px 0px 8px ${theme.palette.softRed}`,
      '&.MuiSlider-active': {
        boxShadow: `0px 0px 0px 14px ${theme.palette.softRed} !important`,
      }
    }
  },
}))

export default ({ font, setCurrentFont }) => {
  const classes = useStyles()
  const sliderClasses = useSliderStyles()
  font = font || {}

  const [ style, setStyle ] = React.useState({
    fontSize: isMobile() ? 30 : 60,
    lineHeight: isMobile() ? '35px' : '70px',
  })

  React.useEffect(() => {
    events.on(actions.SHOW_FONT_MODAL, () => {
      setStyle({
        fontSize: isMobile() ? 30 : 60,
        lineHeight: isMobile() ? '35px' : '70px',
      })
    })
  }, [])

  return (
    <div className={classes.Sample}>
      <Detail title="SAMPLE">
        <div className={classes.Sample_Slider}>
          <span>{style.fontSize}PX</span>
          <Slider classes={sliderClasses} value={style.fontSize} min={8} max={120} onChange={(e, value) => {
            setStyle({
              fontSize: Number(value),
              lineHeight: `${Number(value) * 1.16}px`,
            })
          }} />
        </div>
        <p {...fontProps(font.name, font.demoStyle || 'Regular')} style={style}>{font.sample}</p>
      </Detail>
    </div>
  )
}
