import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { fontProps } from '../../../helpers/fonts'
import Detail from './Detail'

const useStyles = makeStyles(theme => ({
  Specimen: {
    '& p': {
      color: theme.palette.black,
      fontSize: 120,
      lineHeight: '146px',
      margin: 0
    },
    [theme.breakpoints.up('md')]: {
      width: '33%',
      borderRight: `3px solid ${theme.palette.pink}`,
    }
  },
}))

const getSpecimen = (name = '') => {
  if (!name) return ''
  return `${name[0].toUpperCase()}${name[0].toLowerCase()}`
}

export default ({ font, setCurrentFont }) => {
  const classes = useStyles()
  font = font || {}
  return (
    <div className={classes.Specimen}>
      <Detail title="SPECIMEN">
        <p {...fontProps(font.name, font.demoStyle || 'Regular')}>{getSpecimen(font.name)}</p>
      </Detail>
    </div>
  )
}
